import "../css/main.css";
import Alpine from 'alpinejs';
import anchor from '@alpinejs/anchor';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import Tooltip from "@ryangjchandler/alpine-tooltip";
import "./_carousel";
import "./_quote-carousel";
import "./_interactive-map.ts";
import "./_postcode_lookup";
import "./_step_one.js"
import "./_numbers-only.ts";

window.Alpine = Alpine;
Alpine.plugin(anchor);
Alpine.plugin(collapse);
Alpine.plugin(Tooltip);
Alpine.plugin(focus);

// if .form-error is present, scroll into view
const el = document.querySelector('.form-error');
if(el) el.scrollIntoView({ behavior: 'smooth', block: 'center' });

Alpine.start();
