import Alpine from "alpinejs";
import { Loader } from "@googlemaps/js-api-loader";
import { Cluster, ClusterStats, Marker, MarkerClusterer, MarkerUtils, Renderer } from "@googlemaps/markerclusterer";
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

class CustomTitleRenderer implements Renderer {
    /**
     * The default render function for the library used by {@link MarkerClusterer}.
     *
     * Currently set to use the following:
     *
     * ```typescript
     * // change color if this cluster has more markers than the mean cluster
     * const color =
     *   count > Math.max(10, stats.clusters.markers.mean)
     *     ? "#ff0000"
     *     : "#0000ff";
     *
     * // create svg url with fill color
     * const svg = window.btoa(`
     * <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
     *   <circle cx="120" cy="120" opacity=".6" r="70" />
     *   <circle cx="120" cy="120" opacity=".3" r="90" />
     *   <circle cx="120" cy="120" opacity=".2" r="110" />
     *   <circle cx="120" cy="120" opacity=".1" r="130" />
     * </svg>`);
     *
     * // create marker using svg icon
     * return new google.maps.Marker({
     *   position,
     *   icon: {
     *     url: `data:image/svg+xml;base64,${svg}`,
     *     scaledSize: new google.maps.Size(45, 45),
     *   },
     *   label: {
     *     text: String(count),
     *     color: "rgba(255,255,255,0.9)",
     *     fontSize: "12px",
     *   },
     *   // adjust zIndex to be above other markers
     *   zIndex: 1000 + count,
     * });
     * ```
     */
    public render(
      { count, position }: Cluster,
      stats: ClusterStats,
      map: google.maps.Map
    ): Marker {
      // change color if this cluster has more markers than the mean cluster
      const color =
        count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
  
      // create svg literal with fill color
      const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
  </svg>`;
  
      const title = `${count} Locations`,
        // adjust zIndex to be above other markers
        zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + count;
  
      if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
        // create cluster SVG element
        const parser = new DOMParser();
        const svgEl = parser.parseFromString(
          svg,
          "image/svg+xml"
        ).documentElement;
        svgEl.setAttribute("transform", "translate(0 25)");
  
        const clusterOptions: google.maps.marker.AdvancedMarkerElementOptions = {
          map,
          position,
          zIndex,
          title,
          content: svgEl,
        };
        return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
      }
  
      const clusterOptions: google.maps.MarkerOptions = {
        position,
        zIndex,
        title,
        icon: {
          url: `data:image/svg+xml;base64,${btoa(svg)}`,
          anchor: new google.maps.Point(25, 25),
        },
      };
      return new google.maps.Marker(clusterOptions);
    }
  }

Alpine.data('interactiveMap', () => ({
    results: [],
    searched: false,
    error: false,
    searchQuery: '',
    searchRadius: 16093,
    myLocation: null,
    init() {
        const loader = new Loader({
            apiKey: "AIzaSyC3l0_cZWOAp-vGlnVXjPWF52LtSH5vVlI",
            version: "weekly",
            libraries: ["geometry"]

        });

        let locationOptions = { center: { lat: 54.564596, lng: -3.9365972 }, zoom: 6 };

        // if main element has a site--ie class
        if (document.querySelector('.site--ie')) {
            locationOptions = { center: { lat: 53.4232433, lng: -7.3055 }, zoom: 7 };
        }

        let mapOptions = { ...locationOptions, styles: [
            {
                "featureType": "all",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    },
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 40
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    },
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#009281"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#009281"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#0C203D"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#0C203D"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#0C203D"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#0C203D"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]};

        loader.load().then(() => {
            const map = new google.maps.Map(document.getElementById("interactiveMap"), mapOptions);
            const locationsData = mapData.sort(( a, b ) => {
                if ( a.name.toLowerCase() < b.name.toLowerCase() ){
                    return -1;
                }
                if ( a.name.toLowerCase() > b.name.toLowerCase() ){
                    return 1;
                }
                return 0;
            });

            let openedInfoWindow = null;

            const markers = locationsData.map(location => {
                const marker = new google.maps.Marker({
                    position: {lat: parseFloat(location.latLong.lat), lng: parseFloat(location.latLong.long)},
                    map: map,
                    title: location.name,
                    icon: '/wp-content/themes/NextGearCapital/images/marker-icon.svg',
                });

                marker.addListener('click', () => {
                    if (openedInfoWindow) {
                        openedInfoWindow.close();
                    }
                    const infoWindow = new google.maps.InfoWindow({
                        content: this.buildInfoWindowContent(location)
                    });
                    openedInfoWindow = infoWindow;
                    infoWindow.open(map, marker);
                });

                return marker;
            });

            const clusterOptions = {
                styles: [
                  {
                    textColor: 'white',
                    url: '/wp-content/themes/NextGearCapital/images/cluster-icon.svg',
                    height: 50,
                    width: 50,
                    textLineHeight: 50,
                    textWeight: 'bold'
                  }
                ],
                maxZoom: 10
            };

            new MarkerClusterer({ map, markers, clusterOptions, renderer: new CustomTitleRenderer() });

            this.markers = markers;
            this.map = map;

            // Add all locations by default, then search to refine
            this.results = locationsData.map(x => ({ location: x }));
        }).catch(error => {
            console.error("Error loading the Google Maps script", error);
        });
    },
    search(searchQuery, searchRadius) {
        this.results = [];

        if (this.myLocation) {
            // remove previous searched location marker
            this.myLocation.setMap(null);
        }

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: searchQuery }, (results, status) => {
            this.error = false;
            
            if (status === google.maps.GeocoderStatus.OK && results[0]) {
                const searchedLocation = results[0].geometry.location;

                // add searched location as a marker on the map
                this.myLocation = new google.maps.Marker({
                    position: searchedLocation,
                    map: this.map,
                    title: searchQuery,
                });

                let distances = this.markers.map((marker, index) => {
                    let distance = google.maps.geometry.spherical.computeDistanceBetween(
                        marker.getPosition(), searchedLocation
                    );
                    return { distance, marker, location: mapData[index] };
                }).filter(d => d.distance <= searchRadius);

                distances.sort((a, b) => a.distance - b.distance);

                if (distances.length > 0) {
                    this.map.setCenter(distances[0].marker.getPosition());
                    this.map.setZoom(this.calculateZoomLevel(searchRadius));

                    // trigger click on first marker
                    google.maps.event.trigger(distances[0].marker, 'click');


                    this.results = distances.slice(0, 5);
                } else {
                    this.searched = true;
                }
            } else {
                this.error = true;
                console.error("Geocoding failed: " + status);
            }
        });
    },
    calculateZoomLevel(radius) {
        if (radius === '16093') return 16;
        if (radius === '40234') return 15;
        if (radius === '80467') return 14;
        if (radius === '160934') return 13;
        return 6;
    },
    buildInfoWindowContent(location) {
        return `<div class="p-3"><h5 class="h5">${location.name}</h5>
            ${location.telephone_number && `<p class="mt-1">Tel: <a href="tel:${location.telephone_number}">${location.telephone_number}</a></p>`}
            <div class="flex items-center gap-1.5 justify-between mt-3">
                <div class="flex items-center gap-1.5">
                    ${location.more_details_link && `<a href="${location.more_details_link}">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.25 20C39.25 9.36852 30.6315 0.75 20 0.75C9.36852 0.75 0.75 9.36852 0.75 20C0.75 30.6315 9.36852 39.25 20 39.25C30.6315 39.25 39.25 30.6315 39.25 20Z" stroke="#0B213D" style="stroke:#0B213D;stroke:color(display-p3 0.0431 0.1294 0.2392);stroke-opacity:1;" stroke-width="1.5"/>
                            <path d="M20 23.1818V20M20 17.4545V17.1364M13 20C13 16.134 16.134 13 20 13C23.866 13 27 16.134 27 20C27 23.866 23.866 27 20 27C16.134 27 13 23.866 13 20Z" stroke="#0F2D52" style="stroke:#0F2D52;stroke:color(display-p3 0.0588 0.1765 0.3216);stroke-opacity:1;" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>`}
                    ${location.website && `<a href="${location.website}">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.75" y="0.75" width="38.5" height="38.5" rx="19.25" stroke="#0B213D" stroke-width="1.5"/>
                            <path d="M26.7483 20C26.7483 23.7279 23.7262 26.75 19.9983 26.75M26.7483 20C26.7483 16.2721 23.7262 13.25 19.9983 13.25M26.7483 20H13.2483M19.9983 26.75C16.2703 26.75 13.2483 23.7279 13.2483 20M19.9983 26.75C21.3445 26.75 22.4358 23.7279 22.4358 20C22.4358 16.2721 21.3445 13.25 19.9983 13.25M19.9983 26.75C18.6521 26.75 17.5608 23.7279 17.5608 20C17.5608 16.2721 18.6521 13.25 19.9983 13.25M13.2483 20C13.2483 16.2721 16.2703 13.25 19.9983 13.25M14.6508 24.1175C16.1883 23.7725 18.0258 23.57 19.9983 23.57C21.9708 23.57 23.8083 23.7725 25.3458 24.1175M14.6508 15.8825C16.1883 16.2275 18.0258 16.43 19.9983 16.43C21.9708 16.43 23.8083 16.2275 25.3458 15.8825M26.7095 19.2756C27.1102 22.982 24.4304 26.3113 20.7241 26.712C17.0178 27.1127 13.6884 24.4329 13.2877 20.7266C12.887 17.0203 15.5668 13.6909 19.2731 13.2902C22.9795 12.8895 26.3088 15.5693 26.7095 19.2756Z" stroke="#0F2D52" stroke-width="1.25" stroke-miterlimit="10"/>
                        </svg>
                    </a>`}
                    ${location.email && `<a href="mailto:${location.email}">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.75" y="0.75" width="38.5" height="38.5" rx="19.25" stroke="#0B213D" stroke-width="1.5"/>
                            <path d="M13.25 17.1579L20 20.0665L26.75 17.1579M15.275 26H24.725C25.8434 26 26.75 25.1517 26.75 24.1053V15.8947C26.75 14.8483 25.8434 14 24.725 14H15.275C14.1566 14 13.25 14.8483 13.25 15.8947V24.1053C13.25 25.1517 14.1566 26 15.275 26Z" stroke="#0F2D52" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>`}
                </div>
                <a class="btn btn--primary" target="_blank" href="https://www.google.com/maps?saddr=My+Location&daddr=${location.latLong.lat},${location.latLong.long}">Directions</a>
            </div>
        </div>`;
    },
}));
