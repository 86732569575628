document.querySelector(".numbers-only")?.addEventListener("keydown", function (evt) {
    const invalidChars = [
        "-",
        "+",
        "e",
    ];
    
    if (invalidChars.includes((evt as KeyboardEvent).key)) {
        evt.preventDefault();
    }
});