import {autocomplete} from 'getaddress-autocomplete';

const API_KEY = 'eCk8SP7ttEecORfJz0QUVQ44844'

autocomplete(
    'address-lookup',
    API_KEY,
    {
        output_fields: {
            formatted_address_0: 'address1',  /* The id of the element bound to 'formatted_address[0]' */
            formatted_address_1: 'address2',  /* The id of the element bound to 'formatted_address[1]' */
            formatted_address_2: 'address3',  /* The id of the element bound to 'formatted_address[2]' */
            formatted_address_3: 'town',  /* The id of the element bound to 'formatted_address[3]' */
            formatted_address_4: 'county',  /* The id of the element bound to 'formatted_address[4]' */

            latitude: 'latitude',  /* The id of the element bound to 'latitude' */
            longitude: 'longitude',  /* The id of the element bound to 'longitude' */
            town_or_city: 'town',  /* The id of the element bound to 'town_or_city' */
            building_number: 'building_number',  /* The id of the element bound to 'building_number' */
            building_name: 'building_name',  /* The id of the element bound to 'building_name' */
            sub_building_number: 'sub_building_number',  /* The id of the element bound to 'sub_building_number' */
            sub_building_name: 'sub_building_name',  /* The id of the element bound to 'sub_building_name' */
            thoroughfare: 'thoroughfare',  /* The id of the element bound to 'thoroughfare' */
            county: 'county',  /* The id of the element bound to 'county' */
            district: 'district',  /* The id of the element bound to 'district' */

            postcode: 'postcode',  /* The id of the element bound to 'postcode' */
            residential: 'residential'
        },
    },
);

document.addEventListener("getaddress-autocomplete-address-selected", function(e) {
  const address = e.address.formatted_address.filter(Boolean).join(' ');
    if (e.target) {

            e.target.value = address;
    }
});

function registerAutocompleteForDirector(el: HTMLInputElement, id: string) {
    // If element is already registered, return
    if (el.getAttribute('data-getaddress-autocomplete-registered') === 'true') {
        //console.log('element already registered, reregistering', id);
        // return;
    }

    // add registered data attribute
    el.setAttribute('data-getaddress-autocomplete-registered', 'true');

    const suffix = id.replace('directors_postcode_lookup', '');

    if(el.dataset.site?.toLowerCase() === 'ie') {
      // find next ul element to input, using closest
      const suggestionsList = el.closest('div')?.parentElement?.querySelector('ul') as HTMLUListElement;
      registerListeners(el, suggestionsList);
    } else {
      //console.log('registering uk director autocomplete for ' + id, el);
      autocomplete(
          id,
          API_KEY,
          {
              output_fields: {
                  formatted_address_0: 'directors_address1' + suffix,
                  formatted_address_1: 'directors_address2' + suffix,
                  formatted_address_2: 'directors_address3' + suffix,
                  formatted_address_3: 'directors_town' + suffix,
                  formatted_address_4: 'directors_county' + suffix,

                  latitude: 'directors_latitude' + suffix,
                  longitude: 'directors_longitude' + suffix,
                  town_or_city: 'directors_town' + suffix,
                  building_number: 'directors_building_number' + suffix,
                  building_name: 'directors_building_name' + suffix,
                  sub_building_number: 'directors_sub_building_number' + suffix,
                  sub_building_name: 'directors_sub_building_name' + suffix,
                  thoroughfare: 'directors_thoroughfare' + suffix,
                  county: 'directors_county' + suffix,
                  district: 'directors_district' + suffix,

                  postcode: 'directors_postcode' + suffix,
                  residential: 'directors_residential' + suffix,
              }
          },
      );
    }
}

function registerDirectorAutocomplete() {
    //console.log('registering directors autocomplete');
    document.querySelectorAll('.directors_postcode_lookup').forEach((el) => {
        registerAutocompleteForDirector(el as HTMLInputElement, el.id);
    });
}

registerDirectorAutocomplete();

// on directorCountUpdate event, register autocomplete for new directors
window.addEventListener('directorCountUpdate', registerDirectorAutocomplete);

document.addEventListener("getaddress-autocomplete-address-selected", function(e) {
    const countryEl = document.getElementById('countryOfResidence') as HTMLSelectElement;
    let country = e['address']['country'];

    switch(country) {
        case 'England':
        case 'Scotland':
        case 'Wales':
        case 'Northern Ireland':
            country = 'UK';
            break;
    }
    
    if(countryEl) {
        countryEl.value = country;
    }
});

// IE SITE
export interface AutocompleteResponse {
  geometry:           AutocompleteResponseGeometry;
  formatted_address:  string;
  types:              string[];
  id:                 string;
  name:               string;
  item:               Item;
  address_components: AddressComponent[];
}

export interface AddressComponent {
  short_name: string;
  long_name:  string;
  types:      string[];
}

export interface AutocompleteResponseGeometry {
  location: Location;
}

export interface Location {
  lat: number;
  lng: number;
}

export interface Item {
  public_id:          string;
  types:              string[];
  formatted_address:  string;
  geometry:           ItemGeometry;
  name:               string;
  address_components: AddressComponent[];
}

export interface ItemGeometry {
  location: Location;
  viewport: Viewport;
}

export interface Viewport {
  northeast: Location;
  southwest: Location;
}

const searchOptions = {
  apiOrder: ["localities", "places"],
  debounceTime: 0,
  localities: {
      key: 'woos-dd6265c9-de50-3a82-8a3d-0f8615ea09d0', // todo: add key
      fallbackBreakpoint: 0.5,
      params: {
        components: {country: ["IE"]},
        types: ["locality", "postal_code", "address"],
      },
  },
  places: {
      key: 'AIzaSyC3l0_cZWOAp-vGlnVXjPWF52LtSH5vVlI',
      params: {
        types: ["address"],
      },
      minInputLength: 6,
  },
};

let multiSearch;

function registerListeners(inputElement: HTMLInputElement, suggestionsList: HTMLUListElement): void {
  //console.log('registering listeners for', { inputElement, suggestionsList });
  inputElement.addEventListener("input", () => {
    if (inputElement && suggestionsList) {
      const input = inputElement.value;
      input.replace('"', '\\"').replace(/^\s+|\s+$/g, "");
      if (input !== "") {
        //console.log('autocompleteMulti1', input);
        multiSearch.autocompleteMulti(input).then(
          (results) => displaySuggestions(results, inputElement, suggestionsList),
          (error) => console.error(`Error autocomplete localities: ${error}`),
        );
      } else {
        suggestionsList.style.display = "none";
      }
    } else {
      console.error("Input or suggestions list not found", { inputElement, suggestionsList });
    }
  });
  inputElement.addEventListener("keydown", (event) => {
    if (event.key === "Enter") {
      const firstLi = suggestionsList.querySelector("li");
      if (firstLi) {
        firstLi.click();
      }
    }
  });
}

function initWoosmap(inputElement: HTMLInputElement, suggestionsList: HTMLUListElement): void {
    if (inputElement && suggestionsList) {
      registerListeners(inputElement, suggestionsList);
    }

    // @ts-ignore
    multiSearch = window.woosmap.multisearch(searchOptions);
  }

  function handleNoResults(suggestionsList: HTMLUListElement): void {
    const li = document.createElement("li");
    li.innerHTML = "<div class='prediction no-result'>No results found...</div>";
    suggestionsList.appendChild(li);
    suggestionsList.style.display = "block";
  }

  function displaySuggestions(results, inputElement: HTMLInputElement, suggestionsList: HTMLUListElement) {
    if (inputElement && suggestionsList) {
      suggestionsList.innerHTML = "";
      if (results.length > 0) {
        results.forEach((result) => {
          const li = document.createElement("li");
          li.innerHTML = formatPredictionList(result) ?? "";
          li.addEventListener("click", () => {
            inputElement.value = result.description ?? "";
            suggestionsList.style.display = "none";
            multiSearch
              .detailsMulti({ id: result.id, api: result.api })
              .then((details) => {
                displayMultiSearchResponse(details, inputElement, suggestionsList?.closest('fieldset')?.parentElement?.querySelector('.address_details'));
              });
          });
          suggestionsList.appendChild(li);
        });
        suggestionsList.style.display = "block";
      } else {
        handleNoResults(suggestionsList);
      }
    }
  }
  
  function formatPredictionList(result): string {
    const predictionClass = "no-viewpoint";
    const formatted_name = result.highlight;
    let html = "";
    html += `<div class="prediction ${predictionClass}">${formatted_name}</div>`;
    return html;
  }
  
  function displayMultiSearchResponse(selectedResult: AutocompleteResponse, inputElement: HTMLInputElement, parentContainer?: HTMLElement | null): void {
      console.log(selectedResult);

      const addressComponents = selectedResult.item.address_components || [];
      let streetNumber = '';
      let route = '';
      let city = '';
      let county = '';
      let country = '';
      let postcode = '';
      let countryCode = '';
  
      addressComponents.forEach(component => {
        const type = component.types[0];
        switch (type) {
          case 'street_number':
            streetNumber = component.long_name;
            break;
          case 'route':
            route = component.long_name;
            break;
          case 'locality':
          case 'division_level_3':
            city = component.long_name;
            break;
          case 'county':
          case 'administrative_area_level_1':
          case 'state':
            county = component.long_name;
            break;
          case 'postal_code':
            postcode = component.long_name;
            break;
          case 'country':
            country = component.long_name;
            countryCode = component.short_name;
            break;
        }
      });
  
      if(selectedResult.types.includes('postal_code') && selectedResult.name) {
        postcode = selectedResult.name;
      }

      const streetAddress = [streetNumber, route].filter(Boolean).join(' ');
  
      inputElement.value = [streetAddress, city, county, postcode, country].filter(Boolean).join(', ');

      const inputs = {
        'address1': streetAddress,
        'address3': county,
        'town': city,
        'county': county,
        'postcode': postcode,
        'country': country
      };

      const countryEl = parentContainer?.querySelector('#countryOfResidence') as HTMLSelectElement;
      if(countryEl) {
          countryEl.value = country;
      }

      document.querySelectorAll('.directors_postcode_lookup').forEach((el) => {
        const suffix = el.id.replace('directors_postcode_lookup', '');

        inputs['directors_address1' + suffix] = streetAddress;
        inputs['directors_address3' + suffix] = county;
        inputs['directors_town' + suffix] = city;
        inputs['directors_county' + suffix] = county;
        inputs['directors_postcode' + suffix] = postcode;
        inputs['directors_country' + suffix] = country;
      });

      // Update form fields
      Object.entries(inputs).forEach(([id, value]) => {
        let input = parentContainer?.querySelector(`#${id}`) as HTMLInputElement;

        if(!input && !parentContainer) {
          input = document.querySelector(`#${id}`) as HTMLInputElement;
        }

        if (input) {
          input.value = value;
        }
      });
      // if(inputElement) {
      //   //console.log('setting input value', { postcode, inputElement });
      //   inputElement.value = postcode;
      // }
  }

  
  document.addEventListener("DOMContentLoaded", () => {
    if(!document.getElementById("autocomplete-input")) return;
    
    // Wait for window.woosmap to exist, give up after 5 seconds, then call initWoosmap once ready
    let attempts = 0;
    const maxAttempts = 50;
    
    const interval = setInterval(() => {
      attempts++;
      if (window.woosmap) {
        clearInterval(interval);
        initWoosmap(document.getElementById(
            "autocomplete-input",
          ) as HTMLInputElement, document.getElementById(
            "suggestions-list",
          ) as HTMLUListElement);
      } else if (attempts > maxAttempts) {
        clearInterval(interval);
        console.error('Woosmap not loaded after 5 seconds');
      }
    }, 100);
  });
  
  declare global {
    interface Window {
      // currently, the MultiSearch JS API typings are not exported, so we use `any` here
      // @ts-ignore
      woosmap: {
        localities: {
          multisearch: (defaultSearchOptions: any) => any;
        };
      };
    }
  }