let manualAddressInput = false;
const manualAddressFields = document.getElementById('manual-address-fields');


function handleSaveForLater() {
    const save_for_later_button = document.getElementById('save_for_later_button');
    const save_for_later_flag = document.getElementById('save_for_later_flag');

    if (!save_for_later_flag || !save_for_later_button) {
        return;
    }

    save_for_later_button.addEventListener('click', ev => {
        ev.preventDefault();
        save_for_later_flag.value = 1;
        const form = document.getElementById('apply-now');
        if (!form instanceof HTMLFormElement) {
            throw "Expected form to be form element";
        }
        form.submit();
    });
}

function handleManualToggle() {
    const manualToggle = document.getElementById('add-address-manual');

    if (!manualToggle || !manualAddressFields) {
        return;
    }

    manualToggle.addEventListener('click', (ev) => {
        ev.preventDefault();

        manualAddressInput = !manualAddressInput;

        if (manualAddressInput) {
            manualAddressFields.style.display = '';
        } else {
            manualAddressFields.style.display = 'none';
        }
    });
}

function handleWouldLikeToReceiveComms() {
    const wouldLikeToReceive = document.querySelector('input[name="wouldLikeToReceiveComms"]');
    if (!wouldLikeToReceive) {
        return;
    }
    wouldLikeToReceive.addEventListener('change', ev => {
        if (ev.target.checked) {
            document.querySelector('.comm_types').style.display = '';
        } else {
            document.querySelector('.comm_types').style.display = 'none';
        }
    });
}

function handleRemovingValidationErrors() {
    document.querySelectorAll('.form-error').forEach(item => {
console.log("HEY")
        function hideError() {
            item.style.display = 'none';
            item.classList.remove('form-error')
        }

        function showError() {
            item.style.display = '';
            item.classList.add('form-error')
        }

        function showHideError(condition) {
            if (condition) {
                hideError();
            } else {
                showError();
            }
        }

        const field = item
            .parentNode
            .querySelectorAll('input, select');
        if (!field) {
            console.log("ignore")
            return;
        }
        field.forEach(input => {
            input.addEventListener('input', el => {
                const value = el.target.value;
                if (item.textContent === 'Number format not recognised') {
                    const condition = value.length >= 10 && value.length <= 14;
                    showHideError(condition);
                } else {
                    showHideError(value)
                }
            });
        });



    })
}

handleRemovingValidationErrors();
handleWouldLikeToReceiveComms();
handleSaveForLater();
handleManualToggle();
